<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import { z } from 'zod';

const user = useUser();
if (user.value) {
	await navigateTo('/app');
}

const schema = z.object({
	email: z.string().email(),
	password: z.string().min(8),
});

type Schema = z.output<typeof schema>;

const errorMessage = ref('');
const state = reactive({
	email: undefined,
	password: undefined,
});

const { inLoading, isLoading } = useLoading();
const isDisabled = computed(() => isLoading.value);

const i18n = useI18n();

async function onSubmit(event: FormSubmitEvent<Schema>) {
	await inLoading(async () => {
		const res = await $fetch('/api/auth/login', {
			ignoreResponseError: true,
			method: 'POST',
			body: event.data,
			redirect: 'manual',

			onResponseError() {
				console.log('apiUpdateOrganization error');
			},
		});

		if (res.success) {
			await navigateTo('/app');
		} else {
			let message = i18n.t('login.error.invalidEmailOrPassword');
			if (i18n.t(res.message) !== res.message) {
				message = i18n.t(res.message);
			}
			errorMessage.value = message;
		}
	});
}
</script>

<template>
	<div>
		<h1 class="text-3xl font-medium mb-4 text-center mt-12 lg:mt-52 my-8">Sign in</h1>

		<UCard class="max-w-sm mx-auto">
			<!-- <UButton
          block
          class="mb-4"
          variant="outline"
          color="gray"
          to="/auth/google"
          external
        >
          <UIcon name="i-logos-google-icon" class="h-6 w-6 m-1" />
          Sign in with Google
        </UButton>
    
        <UDivider class="my-4" label="OR" /> -->

			<UAlert v-if="errorMessage" :title="errorMessage" class="mb-2" variant="soft" color="red" />

			<UForm :schema="schema" :state="state" class="space-y-4" :disabled="isDisabled" @submit="onSubmit">
				<UFormGroup label="Email" name="email" class="capitalize">
					<UInput v-model="state.email" :disabled="isDisabled" />
				</UFormGroup>

				<UFormGroup :label="$t('password.label')" name="password" class="capitalize">
					<UInput v-model="state.password" type="password" :disabled="isDisabled" />
				</UFormGroup>

				<UButton type="submit" color="success" :loading="isLoading" :disabled="isDisabled">Submit</UButton>

				<!--			<p class="text-sm mt-4">-->
				<!--				Don't have an account?-->
				<!--				<NuxtLink to="/signup" class="text-warning" :disabled="isDisabled">Sign up</NuxtLink>-->
				<!--			</p>-->
			</UForm>
		</UCard>
	</div>
</template>
